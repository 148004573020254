import React, { useContext, useEffect } from "react"
import { Spin } from "antd"
import styled from "styled-components"
import { AuthContext, getUserMetadata } from "./utils/AuthContext"

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
`

const RestrictedRoute = (AuthComponent) => {
  function RestrictedComponent({ children }) {
    const {
      state: { user },
      dispatch,
      value: { loading, result },
    } = useContext(AuthContext)
    const isAuthenticated = result !== null

    useEffect(() => {
      if (isAuthenticated && !user) {
        ;(async () => {
          const { uid, role } = await getUserMetadata(result)
          dispatch({
            type: "SET_USER",
            payload: { result, uid, role },
          })
        })()
      }
      if (isAuthenticated) {
        window.location.href = "/dashboard"
      }
    }, [isAuthenticated, loading, result])

    if (loading) {
      return (
        <LoaderWrapper>
          <Spin size="large" className="loader" />
        </LoaderWrapper>
      )
    }
    return <>{!isAuthenticated && children}</>
  }

  return class Higher extends React.Component {
    render() {
      return (
        <RestrictedComponent>
          <AuthComponent {...this.props} />
        </RestrictedComponent>
      )
    }
  }
}

export default RestrictedRoute
