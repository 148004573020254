import { useFormik } from "formik"
import * as yup from "yup"
import React from "react"
import { message, notification } from "antd"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import Head from "next/head"
import { firebase, theme } from "@project/shared"
import styled from "styled-components"
import * as Sentry from "@sentry/node"
import RestrictedRoute from "../../withRestrictedRoute"
import { CloseCircleFilled } from "@ant-design/icons"
import dynamic from "next/dynamic"
const TextField = dynamic(
  () => import("@project/shared").then((mod) => mod.TextField),
  { ssr: false }
)
const Button = dynamic(
  () => import("@project/shared").then((mod) => mod.Button),
  { ssr: false }
)
interface LoginType {
  email: string
  password: string
}

const Container = styled.div`
  background: ${theme.bgColor};
  height: 100%;
  min-height: calc(100vh - 51px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  & .container-content {
    display: flex;
    flex-direction: column;
  }
`
const LoginWrapper = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  flex-direction: column;
  border: 1px solid ${theme.darkGrey};
  background: ${theme.base};
  padding: 1rem 2rem;
  border-radius: 2px;
  margin-top: 0;
  & .login-text-field {
    & label {
      font-size: 14px;
      line-height: 22px;
      padding-bottom: 8px;
    }
  }
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
  cursor: pointer;
`

const TextFieldWrapper = styled.div`
  margin-top: 35px;
`
const StyledButton = styled(Button)`
  margin: auto;
  height: 40px;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  display: flex;
  width: 96px;
  align-items: center;
  justify-content: center;
`
const InputFieldWrapper = styled.div`
  margin-bottom: 25px;
`

const ForgotPasswordWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-decoration: underline;
  color: #a09fa8;

  & :hover {
    cursor: pointer;
  }
`

const Seperator = styled.div`
  width: 100%;
  background: ${theme.bgColor};
  & div {
    width: 80%;
    margin: auto;
    border-top: 1px solid #eaeaea;
  }
`

const Footer = styled.footer`
  background: ${theme.bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  & a {
    color: #333;
    &:first-child {
      margin-right: 40px;
    }
  }
`

const LoginPage: React.FC = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(false)
  const routers = useRouter()

  const handleLoginFormSubmit = () => {
    handleLogin()
  }

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t("Please enter your e-mail address"))
      .required(t("Please enter")),
    password: yup
      .string()
      .required(t("Please enter"))
      .max(20, "パスワード半角英数字6文字以上20文字以内で入力してください。")
      .min(6, "パスワード半角英数字6文字以上20文字以内で入力してください。"),
  })

  const formik = useFormik<LoginType>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: handleLoginFormSubmit,
  })
  const timeout = window.localStorage.getItem("sessionExpired")
  if (timeout) {
    notification.warning({
      message: t("Your session has expired. Login again"),
    })
    window.localStorage.removeItem("sessionExpired")
  }

  const handleLogin = async () => {
    setLoading(true)
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(formik.values.email, formik.values.password)
    } catch (error) {
      const errorCode = error.code
      if (errorCode === "auth/user-not-found") {
        message.error({
          content: t("Email address or password does not match."),
          key: "13",
          icon: <CloseCircleFilled onClick={() => message.destroy("13")} />,
        })
      } else if (errorCode === "auth/wrong-password") {
        message.error({
          content: t("Email address or password does not match."),
          key: "14",
          icon: <CloseCircleFilled onClick={() => message.destroy("14")} />,
        })
      } else if (errorCode === "auth/user-disabled") {
        message.error({
          content: t(
            "We could not login you at this moment. Please contact your administration from inquiry"
          ),
          key: "42",
          icon: <CloseCircleFilled onClick={() => message.destroy("42")} />,
        })
      } else {
        message.error({
          key: "16",
          icon: <CloseCircleFilled onClick={() => message.destroy("16")} />,
          content: t("An error has occurred. Please try again later."),
        })
      }
      Sentry.captureException(error)
    }
    setLoading(false)
  }

  return (
    <>
      <Head>
        <title>Sales Agent | {t("Login")}</title>
      </Head>
      <>
        <Container>
          <div className="container-content">
            <LogoWrapper>
              <a href={process.env.NEXT_PUBLIC_CONSUMER_HOST} target={"_blank"}>
                <img src="/assets/icons/logo.svg" alt="logo" width="260" />
              </a>
            </LogoWrapper>
            <LoginWrapper>
              <TextFieldWrapper>
                <form onSubmit={formik.handleSubmit}>
                  <InputFieldWrapper>
                    <TextField
                      name="email"
                      error={formik.touched.email && formik.errors.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label={t("Email")}
                      placeholder={t("Email")}
                      className="login-text-field"
                      height="40px"
                      width="340px"
                    />
                  </InputFieldWrapper>
                  <InputFieldWrapper>
                    <TextField
                      name="password"
                      type="password"
                      error={formik.touched.password && formik.errors.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label={t("Password")}
                      placeholder={t("Password")}
                      className="login-text-field"
                      height="40px"
                      width="340px"
                    />
                  </InputFieldWrapper>
                  <StyledButton htmlType="submit" loading={loading}>
                    {t("Login")}
                  </StyledButton>
                  <ForgotPasswordWrapper
                    onClick={() => routers.push("/forgot-password")}
                  >
                    {t("Forgot password")}
                  </ForgotPasswordWrapper>
                </form>
              </TextFieldWrapper>
            </LoginWrapper>
          </div>
        </Container>
        <Seperator>
          <div />
        </Seperator>
        <Footer>
          <a
            href={`${process.env.NEXT_PUBLIC_CONSUMER_HOST}/privacy-policy`}
            target="_blank"
          >
            {t("Privacy policy")}
          </a>
          <a
            href={`${process.env.NEXT_PUBLIC_CONSUMER_HOST}/terms-and-conditions`}
            target="_blank"
          >
            {t("Terms and Conditions")}
          </a>
        </Footer>
      </>
    </>
  )
}

export default RestrictedRoute(LoginPage)
